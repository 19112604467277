<template>
  <div class="parameter-vacancy-control__container">
    <loader v-if="loading" />
    <v-container>
      <v-card light>
        <v-card-title>Параметры вакансий</v-card-title>
        <list-component
          list-title="Требования"
          :list-array="VACANCY.requirements"
          description-mode
          @list-item-delete="deleteRequirement"
          @list-item-add="addRequirement"
        />
        <v-divider></v-divider>
        <list-component
          list-title="Условия"
          :list-array="VACANCY.conditions"
          description-mode
          @list-item-delete="deleteCondition"
          @list-item-add="addCondition"
        />
        <v-divider></v-divider>
        <list-component
          list-title="Задачи"
          :list-array="VACANCY.tasks"
          description-mode
          @list-item-delete="deleteTask"
          @list-item-add="addTask"
        />
        <v-divider></v-divider>
        <list-component
          list-title="Номера телефонов"
          :list-array="VACANCY.callbacks"
          phone-number-mode
          @list-item-delete="deleteCallback"
          @list-item-add="addCallback"
        />
      </v-card>
      <v-card class="mt-5">
        <v-card-title>Вакансии</v-card-title>
        <v-list-group v-for="vacancy in VACANCY.vacancies" :key="vacancy.id">
          <template v-slot:activator>
            <v-list-item-content style="display: flex">
              <v-col cols="10"
                ><v-list-item-title style="font-weight: bold">{{
                  vacancy.name
                }}</v-list-item-title></v-col
              >
              <v-col cols="2" class="d-flex justify-end align-center">
                <v-btn icon @click="deleteVacancy(vacancy.id)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <!-- <v-btn icon @click="openModalEdit(vacancy)">
                  <v-icon>mdi-application-edit</v-icon>
                </v-btn> -->
                <!-- 
                  в данный момент не работает так как не предаются
                  разделение параметров на группы и их id
                 -->
              </v-col>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="vacancyInfo in vacancy.vacancyInfos"
            :key="vacancyInfo.id"
          >
            {{ vacancyInfo.description }}
          </v-list-item>
        </v-list-group>
        <div class="parameter-vacancy-control__button_wrapper">
          <div class="parameter-vacancy-control__button_add">
            <v-btn color="primary" @click="openModalCreate">
              Добавить вакансию
            </v-btn>
          </div>
          <v-pagination
            v-model="vacancyPage"
            :length="VACANCY.vacanciesTotalPages"
            circle
          ></v-pagination>
        </div>
      </v-card>
    </v-container>
    <v-overlay :value="overlay">
      <v-card class="parameter-vacancy-control__modal" light>
        <v-card-title>Создать новую вакансию</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newVacancyName"
            dense
            label="Название вакансии"
          />
          <list-component
            list-title="Требования"
            :list-array="VACANCY.requirements"
            description-mode
            new-vacancy-creation
            :checked-array="arrayOfRequirments"
            @list-item-delete="deleteRequirement"
            @list-item-add="addRequirement"
            @list-item-change-array="changeArray"
          />
          <v-divider></v-divider>
          <list-component
            list-title="Условия"
            :list-array="VACANCY.conditions"
            description-mode
            new-vacancy-creation
            :checked-array="arrayOfConditions"
            @list-item-delete="deleteCondition"
            @list-item-add="addCondition"
            @list-item-change-array="changeArray"
          />
          <v-divider></v-divider>
          <list-component
            list-title="Задачи"
            :list-array="VACANCY.tasks"
            description-mode
            new-vacancy-creation
            :checked-array="arrayOfTasks"
            @list-item-delete="deleteTask"
            @list-item-add="addTask"
            @list-item-change-array="changeArray"
          />
        </v-card-text>
        <v-card-actions class="parameter-vacancy-control__modal_buttons">
          <v-btn class="white--text" color="teal" @click="closeModal">
            Закрыть модальное окно
          </v-btn>
          <v-btn class="white--text" color="error" @click="clearData">
            Очистить поля
          </v-btn>
          <v-btn class="white--text" color="primary" @click="createVacancy">
            Создать вакансию
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "../components/Loader.vue";
import ListComponent from "../components/ListComponentForVacancy.vue";
export default {
  components: { Loader, ListComponent },
  data() {
    return {
      loading: false,
      newRequirement: null,
      vacancySize: 3,
      vacancyPage: 1,
      overlay: false,
      arrayOfRequirments: [],
      arrayOfConditions: [],
      arrayOfTasks: [],
      newVacancyName: "",
    };
  },
  computed: {
    ...mapGetters({
      USER: "User/STATE",
      VACANCY: "Vacancy/STATE",
    }),
  },
  watch: {
    vacancyPage: {
      async handler() {
        await this.getVacancies({
          page: this.vacancyPage - 1,
          size: this.vacancySize,
        });
      },
    },
  },
  async created() {
    await this.checkPowers();
    await this.getRequirements();
    await this.getConditions();
    await this.getTasks();
    await this.getCallbacks();
    await this.getVacancies({
      page: this.vacancyPage - 1,
      size: this.vacancySize,
    });
  },
  methods: {
    ...mapActions({
      getRequirements: "Vacancy/GET_REQUIREMENTS",
      deleteRequirement: "Vacancy/DELETE_REQUIREMENT",
      addRequirement: "Vacancy/ADD_REQUIREMENT",

      getConditions: "Vacancy/GET_CONDITIONS",
      deleteCondition: "Vacancy/DELETE_CONDITION",
      addCondition: "Vacancy/ADD_CONDITION",

      getTasks: "Vacancy/GET_TASKS",
      deleteTask: "Vacancy/DELETE_TASK",
      addTask: "Vacancy/ADD_TASK",

      getCallbacks: "Vacancy/GET_CALLBACKS",
      deleteCallback: "Vacancy/DELETE_CALLBACK",
      addCallback: "Vacancy/ADD_CALLBACK",

      getVacancies: "Vacancy/GET_VACANCIES",
      deleteVacancy: "Vacancy/DELETE_VACANCY",
      addVacancy: "Vacancy/ADD_VACANCY",
    }),
    checkPowers() {
      let state = this.$power.check(this.$route.meta.power);
      if (state === false) {
        this.$router.push("/");
      }
    },
    changeArray(requiredArray, id) {
      let indexOfId = requiredArray.indexOf(id);
      if (indexOfId != -1) {
        requiredArray.splice(indexOfId, 1);
      } else {
        requiredArray.push(id);
      }
    },
    clearData() {
      this.arrayOfRequirments = [];
      this.arrayOfConditions = [];
      this.arrayOfTasks = [];
      this.newVacancyName = "";
    },
    openModalCreate() {
      this.clearData();
      this.overlay = true;
    },
    openModalEdit(vacancy) {
      this.arrayOfRequirments = vacancy.requirements.map((el) => {
        return el.id;
      });
      this.arrayOfConditions = vacancy.conditions.map((el) => {
        return el.id;
      });
      this.arrayOfTasks = vacancy.tasks.map((el) => {
        return el.id;
      });
      this.newVacancyName = vacancy.name;
      this.overlay = true;
    },
    closeModal() {
      this.clearData();
      this.overlay = false;
    },
    async createVacancy() {
      let requestBody = {
        conditions: this.arrayOfConditions,
        departmentId: 0, // дефолтное значение, далее решить данную проблему и добавить выбор филиала
        name: this.newVacancyName,
        requirements: this.arrayOfRequirments,
        tasks: this.arrayOfTasks,
      };
      let response = await this.addVacancy(requestBody);
      if (response.type === "success") {
        this.closeModal();
        await this.getVacancies({
          page: this.vacancyPage - 1,
          size: this.vacancySize,
        });
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.text,
        });
      }
    },
  },
};
</script>
<style lang="scss">
.parameter-vacancy-control__container {
  position: relative;
}
.parameter-vacancy-control__modal {
  width: 80vw;
  max-width: 700px;
  min-width: 300px;
  .parameter-vacancy-control__modal_buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 800px) {
      flex-direction: column;
      * {
        margin-bottom: 10px;
      }
    }
  }
}
.parameter-vacancy-control__button_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  .parameter-vacancy-control__button_add {
    padding: 0 20px;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
    .parameter-vacancy-control__button_add {
      padding: 0;
      padding-bottom: 20px;
    }
  }
}
</style>
